#root{
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  height: 100%;
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
}